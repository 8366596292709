.box {
    padding: 5px;
    background: silver;
}

.box.cerveny {
    background: red;
}

.box b {
    color: red;
}
.box b u {
    color: blue;
}

.box.cerveny i {
    color: blue
}

.cerveny-box1 {
    background: red;
  }
  .cerveny-box1:hover {
    background: silver;
  }
  .cerveny-box1 p {
    color: black;
  }
  .cerveny-box1 p:nth-child(3) {
    color: blue;
  }

  .moj-r-element {
    width: 400px;
    height: 100px;
    background: red;
  }
  @media only screen and (max-width: 600px) {
    .moj-r-element {
      width: 100%;
    }
  }

  .moj-element {
    animation: nazovAnimacie 5s infinite;
    position: absolute;
    width: 100px;
    height: 100px;
  }
  @keyframes nazovAnimacie {
    0% {
      left: 0px;
    }
    50% {
        left: 250px;
      }
    100% {
        left: 0px;
    }
  }

  .t-element {
      width: 100%;
      height: 150px;
      margin-top: 1em;
      background-color: red;
    transition: background-color 1s linear 200ms;
  }
  .t-element:hover {
    background-color: blue;
  }

  .box1, .box2 {color: red;}