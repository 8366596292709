@import "../../styles/variables";

.menu-icon {
    position: absolute;
    bottom: 1.9rem;
    left: .5em;
    font-size: 3rem;
    cursor: pointer;
    z-index: 2;
    color: $disabled;
}

.sidebar {
    .overlay {
        position: absolute;
        z-index: 15;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.5);
        display: none;
    }
    .nav-content {
        position: absolute;
        width: 290px;
        height: 100vh;
        overflow-y: scroll;
        background-color: #191919;
        z-index: 50;
        left: -320px;
        transition: left 300ms ease-in-out;
        padding: 15px;
        hr {
            width: 80%;
            border: 1px solid #767676;
        }
        .download-button {
            color: #ffffff;
            font-size: 1.1rem;
            display: block;
            margin: 0 auto 40px auto;
            text-align: center;
            padding: 10px;
            width: 80%;
            border-radius: 20px;
        }

        .nav-brand {
            text-align: center;
        }
        .nav-list {
            margin: 0;
            padding: 20px 0 0 0;
            font-size: 1.2rem;
            li {
                list-style: none;
                padding: 5px 0;
                font-size: 1.1rem;
                a {
                    padding-right: 25px;
                    position: relative;
                    display: block;
                    color: #ffffff;
                    line-height: 1.3rem;
                    &:hover {
                        color: darken($color: #ffffff, $amount: 20)
                    }
                    .slide-num {
                        position: absolute;
                        right: 0;
                        color: darken($color: #ffffff, $amount: 40)
                    }
                }
            }
            .secondary-nav {
                margin: 0;
                padding-left: 20px;
            }
        }
    }
    &.active {
        .overlay {
            display: block;
        }
        .nav-content {
            left: 0;
        }
    }
}