@import "./variables";


.reveal {
    background: $background-color;

    .progress span {
        background-color: $danger;
    }

    .slide-number {
        display: block;
        left: 3.5em;
        right: auto;
        bottom: 1.75em;
        font-size: 0.6em;
        background: none;
        color: $disabled;
    }
    .controls {
        color: $danger;
    }

    pre {
        width: 100%;box-shadow: none;
        font-size: 0.8em;
        line-height: 1.3em;
    }
    h1 {
        text-align: left;
        font-size: 1.8em;
        margin-top: 2em;
    }
    h2 {
        text-align: left;
        font-size: 1.1em;
    }
    .subtitle {
        text-align: left;
        color: $disabled;
        font-size: .8em;
    }
    .h-hr {
        width: 40%;
        margin-left: 0;
    }
    b {color: $danger;}

    h3 {
        text-align: left;
    }

    ul, ol {margin-left: 0;display: block;}
    ul ul, ol ol, ol ul {margin-left: 0;display: block;font-size: 0.8em;}

    ::-moz-selection {
        background: darken($color: $font-color, $amount: 70);
    }
    ::selection {
    background: darken($color: $font-color, $amount: 70);
    }

    form {
        text-align: left;
        label {font-size: 0.8em;}
        input {
            font-size: 0.8em;
        }
    }
}

