@import "./variables";

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {display: contents;}

.left {text-align: left;}
.center {text-align: center;}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
.col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

.danger {color: $danger;}
.success {color: $success;}